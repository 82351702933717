<template>
  <div  class="wrapper">
    <!-- <div class="text-center mt-3 mb-3"> -->
      <b-card  class="wrapper">
        <b-container>
          <p class="TextBlack32 mt-4 text-center">
            {{ $t("CalculationKind") }}
          </p>

          <b-row>
            <b-col
              sm="12"
              md="12"
              v-for="(item, index) in CalculationKindList"
              :key="index"
            >
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle="'accordion-' + item.id"
                    variant="white"
                    class="text mb-2"
                   
                    >
                    <b-row>
                      <b-col sm="12" lg="11">
                        <p
                      class="school15"
                      style="text-align:left;"
                    >
                      {{ item.name }}
                    </p>
                      </b-col>
                      <b-col style="position:relative" sm="12" lg="1">
                         <b-icon class="IconStyle mr-2 iconLocation" :icon="'chevron-down'"></b-icon
                  >
                      </b-col>
                    </b-row>
                   </b-button>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + item.id"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ item.description }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    <!-- </div> -->
  </div>
</template>
<script>
import CalculationKindService from "@/services/CalculationKind.service";
export default {
  data() {
    return {
      ischange: false,
      CalculationKindList: [],
      filter: {
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        PageNumber: 1,
        PageLimit: 1000,
      },
    };
  },
  created() {
    this.filter.lang = localStorage.getItem("lang") || "uz_cyrl";
    this.Refresh();
  },
  methods: {
    Refresh() {
      CalculationKindService.GetList(
        this.filter.Search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.lang
      ).then((res) => {
        this.CalculationKindList = res.data.rows;
      });
    },
  },
};
</script>
<style>
.IconStyleNow {
  transform: rotate(180deg);
}
.IconStyleNowChange {
  transform: rotate(0);
}
.iconLocation{
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
   transform: translateY(-50%);
}
</style>
